<script setup lang="ts">
import { getCurrentInstance, inject, onMounted, ref } from 'vue';
import { MSpinner } from '@ca-crowdfunding/makuake-ui-n';
import PaymentLayout from '@/components/common/PaymentLayout.vue';
import MRadio from '@/components/common/MRadio.vue';
import Divider from '@/components/common/Divider.vue';
import InputCreditRegisteredCardForm from './InputCreditRegisteredCardForm.vue';
import InputCreditNewCardForm from './InputCreditNewCardForm.vue';
import Buttons from '@/components/common/Buttons.vue';
import router from '@/router';
import InputCreditLabel from './InputCreditLabel.vue';
import {
  type Error,
  ErrorCode,
  PaymentType,
  ProjectType,
  type TranAvailablePaymentTypeResponse,
  type TranCardsResponse,
  type TranCheckRequest,
  TranService,
} from '@/generated/payment-api';
import type { AxiosError } from 'axios';
import { type errorStore, errorStoreKey } from '@/store/error';
import {
  type CreditCardFormStore,
  CreditCardFormKey,
} from '@/store/CreditCardForm';
import {
  type MultiPayment,
  type MultiPaymentElements,
  type MultiPaymentElement,
  type TokenResponse,
  type ErrorResponse,
  type TokenObj,
} from '@mul-pay/mptoken-js';

import { isTimeoutError } from '@/generated/payment-api/core/request';
const paymentTypeOptions: { label: string; value: boolean }[] = [
  { label: '登録済のクレジットカードを利用する', value: false },
  { label: '新しいクレジットカードを利用する', value: true },
];

const store = inject(errorStoreKey) as errorStore;
const cardForm = inject(CreditCardFormKey) as CreditCardFormStore;
const errorMessage = ref<string[]>([]);
const isLoadingContents = ref<boolean>(true);
const isLoadingButton = ref<boolean>(false);

onMounted(() => {
  if (store.code !== '') {
    router.push(`/error`);
    return;
  }
  TranService.tranAvailablePaymentType()
    .then((response: TranAvailablePaymentTypeResponse) => {
      cardForm.isDonation = response.project_type === ProjectType.DONATION;
      handleCards();
    })
    .catch((error: AxiosError<Error, any>) => {
      if (!error.response) {
        isTimeoutError(error, store, false) ? router.push(`/error`) : null;
        return;
      }
      const { status } = error.response;
      const { code, message } = error.response.data;
      if (status === 401 && code === ErrorCode.AUTHENTICATION_ERROR) {
        window.location.assign(import.meta.env.VITE_MAKUAKE_URL + '/login/');
        return;
      }
      store.code = code;
      store.messages = [message];
      router.push(`/error`);
    });
});

const handleCards = () => {
  TranService.tranCards()
    .then((response: TranCardsResponse) => {
      cardForm.registeredCardList = response.map((v) => ({
        label: `${v.cardNumber}(${v.expiryMonth}/${v.expiryYear})`,
        value: v.cardId,
      }));
      isLoadingContents.value = false;
    })
    .catch((error: AxiosError<Error, any>) => {
      if (!error.response) {
        isTimeoutError(error, store, false) ? router.push(`/error`) : null;
        return;
      }
      const { status } = error.response;
      const { code, message } = error.response.data;
      if (status === 401 && code === ErrorCode.AUTHENTICATION_ERROR) {
        window.location.assign(import.meta.env.VITE_MAKUAKE_URL + '/login/');
        return;
      }
      store.code = code;
      store.messages = [message];
      router.push(`/error`);
    });
};

const getToken = (element: MultiPaymentElement) => {
  return new Promise<TokenObj>((resolve, reject) => {
    multipayment
      .getTokenThroughIframe(element, cardForm.holderName)
      .then((response: TokenResponse) => {
        if (response.result === 'success') {
          resolve(response as TokenObj);
        } else {
          reject(response as ErrorResponse);
        }
      });
  });
};

const handleNegative = () => {
  isLoadingButton.value = true;
  cardForm.clear();
  router.push('/select');
};

const handlePositive = () => {
  if (cardForm.useNewCard) {
    handlePositiveNewCard();
  } else {
    handlePositiveRegisteredCard();
  }
};

const mpTokenErrorMesssageMap = new Map<string, string>([
  ['100', '『クレジットカード番号』が入力されていません。'],
  ['101', '『クレジットカード番号』は数字のみを入力してください。'],
  ['102', '『クレジットカード番号』は10桁以上、16桁以内で入力してください。'],
  ['110', '『有効期限』が入力されていません。'],
  ['111', '『有効期限』は数字のみを入力してください。'],
  ['112', '『有効期限』が正しく選択されていません。'],
  ['113', '『有効期限』は1月から12月を入力してください。'],
  ['121', '『セキュリティコード』は数字のみを入力してください。'],
  ['122', '『セキュリティコード』は4桁以内で入力してください。'],
  ['131', '『カード名義人』は半角英数字のみを入力してください。'],
  ['132', '『カード名義人』は50桁以内で入力してください。'],
  ['901', 'エラーが発生しました。'],
  ['902', 'エラーが発生しました。'],
]);

const app = getCurrentInstance();
const multipayment: MultiPayment =
  app?.appContext.config.globalProperties.$useMultiPayment();
const elements: MultiPaymentElements =
  app?.appContext.config.globalProperties.$useElements();

const handlePositiveNewCard = () => {
  isLoadingButton.value = true;
  if (!cardForm.isInputedHolderName()) {
    isLoadingButton.value = false;
    errorMessage.value = ['『カード名義人』が入力されていません。'];
    window.scrollTo(0, 0);
    return;
  }
  const element = elements.getElement('cardNumber') as MultiPaymentElement;
  getToken(element)
    .then((response: TokenObj) => {
      if (!response.isSecurityCodeSet) {
        isLoadingButton.value = false;
        errorMessage.value = ['『セキュリティコード』が入力されていません。'];
        window.scrollTo(0, 0);
        return;
      }
      const mpToken = response.tokenList[0];
      const body: TranCheckRequest = {
        payment_type: PaymentType.CARD,
        card: {
          registers_card: cardForm.shouldRegisterCard,
          use_new_card: cardForm.useNewCard,
          token: mpToken,
        },
      };
      tranCheck(body);
    })
    .catch((response: ErrorResponse) => {
      isLoadingButton.value = false;
      const message = mpTokenErrorMesssageMap.get(
        response.errorList[0].legacyCode,
      );
      if (message) {
        errorMessage.value = [message];
      } else {
        errorMessage.value = ['不正なリクエストです。'];
      }
      window.scrollTo(0, 0);
    });
};

const handlePositiveRegisteredCard = () => {
  isLoadingButton.value = true;
  const body: TranCheckRequest = {
    payment_type: PaymentType.CARD,
    card: {
      registers_card: false,
      use_new_card: false,
      card_id: cardForm.getSelectedCardId(),
    },
  };
  tranCheck(body);
};

const tranCheck = (body: TranCheckRequest) => {
  TranService.tranCheck(body)
    .then(() => {
      cardForm.clear();
      router.push('/confirm');
    })
    .catch((error: AxiosError<Error, any>) => {
      isLoadingButton.value = false;
      if (!error.response) {
        isTimeoutError(error, store, false) ? router.push(`/error`) : null;
        return;
      }
      const { status } = error.response;
      const { code, message, details } = error.response.data;

      if (status === 400 && code === ErrorCode.INVALID_ARGUMENT && details) {
        errorMessage.value = Object.values(details);
        window.scrollTo(0, 0);
        return;
      }
      if (status === 401 && code === ErrorCode.AUTHENTICATION_ERROR) {
        window.location.assign(import.meta.env.VITE_MAKUAKE_URL + '/login/');
        return;
      }
      store.code = code;
      store.messages = [message];
      router.push(`/error`);
    });
};
</script>

<template>
  <PaymentLayout
    :step="2"
    title="クレジットカードの情報を入力してください"
    :errorMessages="errorMessage"
  >
    <template v-slot:contents>
      <template v-if="isLoadingContents">
        <MSpinner size="medium" />
      </template>
      <template v-else>
        <div class="card-form">
          <template v-if="cardForm.hasRegisteredCard()">
            <InputCreditLabel
              text="クレジットカードの指定方法を選択してください。"
            />
            <MRadio
              v-model="cardForm.useNewCard"
              name="is-new-card"
              :options="paymentTypeOptions"
              maxlength="16"
              class="is-new-card"
            />
            <Divider />
          </template>
          <template v-if="cardForm.useNewCard">
            <InputCreditNewCardForm />
          </template>
          <template v-else>
            <InputCreditRegisteredCardForm />
          </template>
        </div>
      </template>
    </template>
    <template v-slot:buttons>
      <MSpinner v-if="isLoadingButton" size="medium" />
      <Buttons
        v-if="!isLoadingButton"
        :handleNegative="handleNegative"
        :handlePositive="handlePositive"
      />
    </template>
  </PaymentLayout>
</template>

<style scoped>
.card-form {
  padding: 0 14px 0;
}
</style>
