import type { Vue } from 'node_modules/@sentry/vue/build/types/types';
import * as Sentry from '@sentry/vue';

const setupSentry = (app: Vue) => {
  if (import.meta.env.MODE === 'dev') {
    return;
  }
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_VUE_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.browserProfilingIntegration(),
    ],
    tracesSampleRate: 0.1,
    tracePropagationTargets: ['localhost'],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    profilesSampleRate: 1.0,
  });
};

export default setupSentry;
